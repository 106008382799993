import * as React from "react";
import {
  Button, Checkbox, Container, Divider, Form, Grid, Header, Icon,
  Input, Message, Segment
} from "semantic-ui-react";
import * as actions from "../../actions";
import {connect} from "react-redux";
import {navigateTo} from "../../helpers/router";
import {
  ISurvey,
  IUser
} from "../../types";
import {t} from "../../i18n";
import {Link} from "gatsby";
import ScrollAnimation from 'react-animate-on-scroll';
import {AxiosResponse} from "axios";
import SEO from "../seo";
import Cloud from "../backgrounds/others/Cloud";

interface StateProps {
  login: AxiosResponse
  show: boolean
  user: IUser
  survey: ISurvey
  google: any
}

export function mapStateToProps(state: any): StateProps {
  return {
    login: state.loginUser,
    show: state.showLogin,
    user: state.user,
    survey: state.surveyActions,
    google: state.loginViaGoogle
  };
}

interface DispatchProps {
  loginUser: typeof actions.loginUser
  showLogin: typeof actions.showLogin
  showRegister: typeof actions.showRegister
  getUserSurvey: typeof actions.getUserSurvey
  getUser: typeof actions.fetchUser
  loginViaGoogle: typeof actions.loginViaGoogle
}

const mapDispatchToProps = {
  loginUser: actions.loginUser,
  showLogin: actions.showLogin,
  showRegister: actions.showRegister,
  getUserSurvey: actions.getUserSurvey,
  getUser: actions.fetchUser,
  loginViaGoogle: actions.loginViaGoogle
}

type Props = StateProps & DispatchProps;

interface State {
  email: string,
  password: string,
  button: boolean,
  errors: string,
  showError: boolean,
  redirect: boolean
}

class Login extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      button: false,
      errors: "",
      showError: false,
      redirect: false,
    };
  }

  handleLoginForm(e: React.FormEvent): void {
    e.preventDefault();
    let formData = {email: this.state.email, password: this.state.password};
    let surveyCode = null
    if (typeof window !== `undefined`) {
      surveyCode = localStorage.getItem("surveyCode")
    }
    if (surveyCode) formData["surveyCode"] = surveyCode;
    this.setState({button: true}, () => this.props.loginUser(formData));
  }

  handleInputChange(e: any, data: any): void {
    // @ts-ignore
    this.setState({[data.name]: data.value});
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (prevProps.google !== this.props.google) {
      let google = this.props.google;
      if (typeof window !== `undefined`)
        window.location.href = google.url;
    }
    if (prevProps.login !== this.props.login) {
      let login = this.props.login;
      if (login.data) {
        this.props.showLogin(false);
        this.setState({redirect: true}, () => this.props.getUser());
      } else {
        console.log("unauthorized");
        this.setState({
          errors: login.statusText,
          showError: true,
          button: false
        });
      }
    }
    if (this.state.redirect && prevProps.user !== this.props.user) {
      let redirectUrl = '/';
      if(this.props.location.state && this.props.location.state.redirectUrl){
        redirectUrl = this.props.location.state.redirectUrl;
      }
      this.setState({redirect: false}, () => navigateTo(redirectUrl));
    }
  }

  hideLoginShowRegister() {
    this.props.showRegister(true);
    this.props.showLogin(false);
  }

  googleLogin(e: React.FormEvent): void {
    e.preventDefault();
    this.props.loginViaGoogle();
  }

  render() {
    return (
      <Cloud>
        <Segment basic id="cloud-panel" className="login-container">
          <SEO title="Logowanie do portalu klienta" 
            description="Zaloguj się do swojego konta i spersonalizuj suplement dopasowany do Ciebie. Sprawdź, co warto suplementować na co dzień!"
          />
          <Container>
            <ScrollAnimation animateIn='fadeInLeft' animateOut='fadeOutRight'>
              <div className="login-form-container">
                <Grid stackable>
                  <Grid.Row>
                    <Grid.Column>
                      <Header as="h1" textAlign="center">{t('Zaloguj się')}</Header>
                      <Divider hidden/>
                      <Form onSubmit={this.handleLoginForm.bind(this)}>
                        <p>{t('e-mail')}</p>
                        <Form.Field>
                          <Input
                            // placeholder={t("e-mail")}
                            name="email"
                            type="email"
                            onChange={this.handleInputChange.bind(this)}
                            value={this.state.email}
                          />
                        </Form.Field>
                        <Form.Field>
                          <p>{t('hasło')}</p>
                          <Input
                            // placeholder={t("Password")}
                            name="password"
                            type="password"
                            onChange={this.handleInputChange.bind(this)}
                            value={this.state.password}
                          />
                        </Form.Field>
                        <Checkbox label={t("pozostań zalogowany")}/>
                        <br/>
                        <Message
                          error
                          header={"Błąd"}
                          visible={this.state.showError}
                          content={this.state.errors}
                        />
                        <Divider hidden/>
                        {/*<Button primary type="submit" disabled={this.state.button}>*/}
                        <Button primary className='shadow-light'>
                          {t('dalej')}
                        </Button>
                        lub
                        <Button primary className='shadow-light' onClick={this.googleLogin.bind(this)}>
                          <Icon name="google"/>
                          {t('logowanie Google')}
                        </Button>
                        <Divider hidden/>
                        <p>{t("Nie masz konta? ")}
                          <Link to="/register" className='link'> Zarejestruj się!</Link>
                        </p>
                        <p>
                          <Link to="/reset/forget" className='link'>Resetowanie hasła</Link>
                        </p>
                      </Form>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </div>
            </ScrollAnimation>
          </Container>
        </Segment>
      </Cloud>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);